import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { GlobalContext } from '../data/GlobalContext';

const VideoPromocional = ({ linkVideo, image, title, text }) => {
    const dbData = useContext(GlobalContext);
    return (
        <div
            className='bgCountentVideo'
            style={{
                backgroundImage: `url("${image ? image : dbData?.stock?.[0]}")`
            }}
        >
            <div className='countentVideoInfo '>
                <div className='info'>
                    <h2>{title}</h2>
                    <p>{text}</p>
                    <Link className="thm-btn dynamic-radius text-capitalize" to="/contact" style={{backgroundColor: 'white', color:'green'}}>
                        free estimate
                    </Link>
                </div>
                <iframe
                    title="Promotional video"
                    className='videoContent'
                    id="ytplayer"
                    type="text/html"
                    src={`https://www.youtube.com/embed/${linkVideo}?&autoplay=1&loop=1&rel=0&showinfo=0&color=white&iv_load_policy=3&playlist=${linkVideo}`}
                    frameborder="0" allowfullscreen />
            </div>
        </div>
    )
}

export default VideoPromocional