import { useContext } from "react";
import { GlobalContext } from "../components/data/GlobalContext";

import Layout from "../components/Layout";
import PagesHeader from "../components/PagesHeader";



const Reviews = () => {
    const dbData = useContext(GlobalContext);

    return (
        <Layout pageTitle="Reviews">
            <PagesHeader title="Reviews" crumbTitle="Reviews" pageHeaderBg={dbData.stock[11]} />
            <div className="py-5 container">
                <h2 className="text-center fw-bold py-5">Our Reviews</h2>
                <div className="elfsight-app-2912f843-7c39-4bd4-b460-c2cdf3008261"></div>
            </div>
        </Layout>
    );
}
export default Reviews;